<script>


/**
 * Terminate PM Diloag
 */

 import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

 import {
  required,
} from "vuelidate/lib/validators";





export default {

    emits: ['confirm', 'cancel'],

    props: {
        property : {
            type : Object,
            default: ()=> {

            }
        }
    },

    components: {
        flatPickr
    },

    validations: {
       date : {required}
    },
 

    data() {
      
        return {
            date : '',
            note : '',
            submitted : false,
        }
       
    },
    methods: {
        formSubmit() {
            this.$v.$touch()
           
            if (this.$v.$invalid == false) {
                let data = {
                    property_id : this.property.property_id,
                    note        : this.note,
                    date        : this.date
                }
                this.$emit('confirm', data)
            }
            
        },
    },

    created() {
       
    },

    mounted() {
       
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom02">Terminate Date</label>
                            <flat-pickr v-model="date" placeholder="Select a date" class="form-control"
                                :class="{
                                    'is-invalid': $v.date.$error,
                                }"></flat-pickr>
                            
                            <div v-if="$v.date.$error" class="invalid-feedback">
                                <span v-if="!$v.date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom02">Note</label>
                            <input  v-model="note" placeholder="Select a date" class="form-control" />
                            
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Submit</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>